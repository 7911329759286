import { useCallback } from 'react';
import { formatDate } from '../../utils/dataProcessing';
import { additionalEndpoints } from '../../config/dataContextConfig';

export const useCustomPeriodData = (props) => {
    const {
        accounts,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setIsCustomPeriodDataLoaded,
        setCustomStartDate,
        setCustomEndDate,
        dataTypes
    } = props;

    const loadCustomPeriodData = useCallback(async (startDate, endDate) => {
        if (!accounts || !startDate || !endDate) return;

        try {
            setLoadingStates(prev => ({ ...prev, customPeriod: true }));
            setIsCustomPeriodDataLoaded(false);

            // Set the dates in global context
            setCustomStartDate(startDate);
            setCustomEndDate(endDate);

            const customPeriod = {
                startDate: formatDate(startDate),
                endDate: formatDate(endDate)
            };

            const newData = { customPeriod: {} };

            // Prepare all promises in parallel
            const allPromises = dataTypes.flatMap(({ key }) => {
                if (!accounts?.[key] || key === 'reviews') return [];

                newData.customPeriod[key] = {};

                return accounts[key].map(async (id) => {
                    try {
                        const apiKey = key === 'ga4-organic' ? 'ga4' : key;

                        // Base data fetch
                        const result = await fetchDataForType(
                            apiKey,
                            id,
                            null,
                            null,
                            customPeriod,
                            '',
                            false,
                            true,
                            key === 'ga4-organic'
                        );

                        if (result.previousPeriod) {
                            newData.customPeriod[key][id] = result.previousPeriod;

                            // GA4 specific endpoints
                            if ((key === 'ga4' || key === 'ga4-organic') && result.previousPeriod) {
                                const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                                    const endpointResult = await fetchDataForType(
                                        apiKey,
                                        id,
                                        null,
                                        null,
                                        customPeriod,
                                        endpoint,
                                        false,
                                        true,
                                        key === 'ga4-organic'
                                    );
                                    return { endpoint: endpoint.slice(1), ...endpointResult };
                                });

                                const endpointResults = await Promise.all(endpointPromises);
                                endpointResults.forEach(({ endpoint, previousPeriod: periodData }) => {
                                    if (periodData) {
                                        newData.customPeriod[key][id] = {
                                            ...newData.customPeriod[key][id],
                                            [endpoint]: periodData
                                        };
                                    }
                                });
                            }
                        }

                        // Ecommerce specific logic
                        if (key === 'ecommerce') {
                            const [overview, items, categories] = await Promise.all([
                                fetchDataForType('ga4', id, null, null, customPeriod, '/ecommerce', false, true),
                                fetchDataForType('ga4', id, null, null, customPeriod, '/ecommerce/items', false, true),
                                fetchDataForType('ga4', id, null, null, customPeriod, '/ecommerce/categories', false, true)
                            ]);

                            newData.customPeriod[key][id] = {
                                overview: overview?.previousPeriod,
                                items: items?.previousPeriod,
                                categories: categories?.previousPeriod
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching custom period data for ${key}:`, error);
                    }
                });
            });

            await Promise.all(allPromises);

            setGlobalData(prev => ({
                ...prev,
                customPeriod: newData.customPeriod
            }));

            setIsCustomPeriodDataLoaded(true);
        } finally {
            setLoadingStates(prev => ({ ...prev, customPeriod: false }));
        }
    }, [accounts, fetchDataForType, setLoadingStates, setGlobalData, setIsCustomPeriodDataLoaded, setCustomStartDate, setCustomEndDate, dataTypes]);

    return { loadCustomPeriodData };
};
