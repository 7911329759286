import { React, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./auth/AuthContext";
import { GlobalDataProvider } from './contexts/GlobalDataContext';
import { YOYProvider } from './contexts/YOYContext';
import { ComponentVisibilityProvider } from './contexts/ComponentVisibilityContext';
import Login from "./auth/Login";
import ProtectedRoute from "./auth/ProtectedRoute";
import ForgotPassword from "./auth/ForgotPassword";
import ResetPassword from "./auth/ResetPassword";
import SetPassword from "./auth/SetPassword";
import Dashboard from "./components/Dashboard";
import Admin from "./components/Admin";

function RootRoute() {
    const { isAdmin } = useAuth();

    if (isAdmin) {
        return <Navigate to="/admin" replace />;
    }

    return (
        <ProtectedRoute>
            <Dashboard />
        </ProtectedRoute>
    );
}

function App() {
    return (
        <Router>
            <AuthProvider>
                <GlobalDataProvider>
                    <YOYProvider>
                        <ComponentVisibilityProvider>
                            <Routes>
                                <Route path="/login" element={<Login />} />
                                <Route path="/forgot-password" element={<ForgotPassword />} />
                                <Route path="/reset-password/:token" element={<ResetPassword />} />
                                <Route path="/set-password/:token" element={<SetPassword />} />
                                <Route path="/" element={<RootRoute />} />
                                <Route
                                    path="/admin"
                                    element={
                                        <ProtectedRoute>
                                            <Admin />
                                        </ProtectedRoute>
                                    }
                                />
                                <Route
                                    path="/admin/:id"
                                    element={
                                        <ProtectedRoute>
                                            <Suspense fallback={null}>
                                                <Dashboard />
                                            </Suspense>
                                        </ProtectedRoute>
                                    }
                                />
                            </Routes>
                        </ComponentVisibilityProvider>
                    </YOYProvider>
                </GlobalDataProvider>
            </AuthProvider>
        </Router>
    );
}

export default App;
