import { createContext, useState, useContext, useEffect, useMemo } from "react";
import { getReportingTheme } from "../Utilities";
import axiosDataPipeline from '../api/axiosDataPipeline';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    // Initialize token from localStorage and set axios headers immediately
    const [token, setToken] = useState(() => {
        const storedToken = localStorage.getItem("token");
        if (storedToken) {
            axiosDataPipeline.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }
        return storedToken;
    });
    const [isAdmin, setIsAdmin] = useState(false);
    const [isRangeAdmin, setIsRangeAdmin] = useState(false);
    const [loading, setLoading] = useState(true);
    const theme = useMemo(() => getReportingTheme(), []);

    // Update axios defaults whenever token changes
    useEffect(() => {
        if (token) {
            axiosDataPipeline.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        } else {
            delete axiosDataPipeline.defaults.headers.common['Authorization'];
        }
    }, [token]);

    // Add axios interceptor to handle auth errors globally
    useEffect(() => {
        const interceptor = axiosDataPipeline.interceptors.response.use(
            response => response,
            error => {
                if (error.response?.status === 401 || error.response?.status === 403) {
                    handleAuthError();
                }
                return Promise.reject(error);
            }
        );

        // Cleanup interceptor on unmount
        return () => {
            axiosDataPipeline.interceptors.response.eject(interceptor);
        };
    }, []);

    const checkAdmin = async () => {
        try {
            const response = await axiosDataPipeline.get('/user/permissions');
            
            // First check admin status
            const isAdminUser = response?.data?.admin ?? false;
            setIsAdmin(isAdminUser);
            
            // Set isRangeAdmin only if both conditions are true
            setIsRangeAdmin(isAdminUser && response?.data?.org === 'range-marketing');
        } catch (err) {
            console.error('Admin check failed:', err);
            handleAuthError();
        }
    };

    const handleAuthError = () => {
        setToken(null);
        setIsAdmin(false);
        localStorage.clear();
        
        // Only redirect if not already on login page
        if (!window.location.pathname.includes('/login')) {
            window.location.href = '/login';
        }
    };

    const validateToken = async (storedToken) => {
        if (!storedToken) return false;
        
        try {
            // Instead of checking /user/validate, check /user/permissions
            // since we know that endpoint exists and can verify the token
            const response = await axiosDataPipeline.get('/user/permissions');
            setIsAdmin(response.data?.admin); // Update admin status while we're at it
            return true;
        } catch (err) {
            if (err.response?.status === 404) {
                // If it's just a 404, don't treat it as an auth error
                // The endpoint might not exist, but the token could still be valid
                return true;
            }
            handleAuthError();
            return false;
        }
    };

    // Initialize auth state
    useEffect(() => {
        const initializeAuth = async () => {
            const storedToken = localStorage.getItem("token");
            if (storedToken) {
                const isValid = await validateToken(storedToken);
                if (isValid) {
                    setToken(storedToken);
                } else {
                    handleAuthError();
                }
            }
            setLoading(false);
        };

        initializeAuth();
    }, []); // Only run on mount

    // Check admin status whenever token changes and auth is not loading
    useEffect(() => {
        if (token && !loading) {
            checkAdmin();
        } else {
            setIsAdmin(false);
        }
    }, [token, loading]);

    const login = async (newToken) => {
        try {
            localStorage.setItem("token", newToken);
            setToken(newToken);
            await checkAdmin(); // Verify admin status immediately after login
        } catch (err) {
            handleAuthError();
        }
    };

    const logout = () => {
        handleAuthError();
    };

    // Don't render anything while loading
    if (loading) {
        return null;
    }

    return (
        <AuthContext.Provider value={{ 
            token, 
            isAdmin,
            isRangeAdmin,
            login, 
            logout, 
            loading, 
            theme 
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    console.log('auth context', context);
    if (!context) {
        throw new Error("useAuth must be used within an AuthProvider");
    }
    return context;
};

export const useTheme = () => {
    const { theme } = useAuth();
    return theme;
};

export default AuthContext;
