export const getTooltip = (component, key) => {
    return tooltips[component]?.[key];
};

export const useTooltip = (setTooltipVisibility, tooltipKey) => ({
    onMouseEnter: () => setTooltipVisibility(tooltipKey),
    onMouseLeave: () => setTooltipVisibility(null)
});

export const tooltips = {
    snapshot: {
        'Contact Forms' : 'Contact form submissions from your website.',
        'Phone Calls from Google' : 'Clicks to call from Google Maps listings.'
    },
    ga4: {
        'sessions' : 'How many unique users visited your website.  Formally called sessions in Google Analytics.'
    },
    gsc: {
        'topQueries' : 'Keywords with the most impressions for your website in Google Search.'
    }
};
