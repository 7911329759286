import React, { useState, useCallback } from 'react';
import { InformationCircleIcon } from '@heroicons/react/24/solid';

const Tooltip = ({ text, className, direction = 'r' }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    
    const baseClass = 'rr-tooltip';
    const combinedClass = className ? `${baseClass} ${className}` : baseClass;

    const handleMouseEnter = useCallback(() => {
        if (timeoutId) clearTimeout(timeoutId);
        setShowTooltip(true);
    }, [timeoutId]);

    const handleMouseLeave = useCallback(() => {
        const id = setTimeout(() => setShowTooltip(false), 5);
        setTimeoutId(id);
    }, []);

    return (
        <div
            className={combinedClass}
            data-direction={direction}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <InformationCircleIcon
                className='rr-tooltip-icon'
            />
            {showTooltip && (
                <div
                    className='rr-tooltip-body'
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <div className='rr-tooltip-arrow' />
                    {text}
                </div>
            )}
        </div>
    );
};

export default Tooltip;
