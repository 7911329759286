import React, { useState } from 'react';
import {
    useYOY,
    formatNumber,
    shouldShowPercentChange,
    getTooltip,
    useTooltip
} from './sharedImports';
import Tooltip from '../Tooltip';

export const MetricCard = ({
    label,
    value,
    yearOverYearChange,
    yearOverYearValue,
    previousPeriodChange,
    previousPeriodValue,
    customPeriodChange,
    customPeriodValue,
    isCallTracking = false,
    cardClass,
    currency = false,
    isSelected,
    multiSelect = false,
    component,
    componentKey
}) => {
    const { showYOY, showPrevPeriod, showCustomPeriod } = useYOY();
    const displayValue = isCallTracking ? value : formatNumber(value, currency);
    const [activeTooltip, setActiveTooltip] = useState(null);
    const metricTooltip = getTooltip(component, componentKey);

    const selectedStyles = multiSelect
        ? '!bg-blue-50'
        : null;

    const renderChangeIndicator = (change, compareValue, label) => {
        // Only check if the percentage change is valid
        if (!shouldShowPercentChange(change) || isNaN(change)) {
            return null;
        }

        // Check if we have a valid comparison value
        const hasValidCompareValue = compareValue !== null &&
            compareValue !== undefined &&
            !isNaN(compareValue);

        // Format the comparison value if it's valid
        const formattedCompareValue = hasValidCompareValue ? (
            isCallTracking ? compareValue : formatNumber(compareValue, currency)
        ) : null;

        return (
            <div className={`metric-card-change ${change >= 0 ? 'text-green-500' : 'text-red-500'}`}>
                <div className="flex items-center gap-2">
                    <span>{label}:</span>
                    <span>
                        {change >= 0 ? '▲' : '▼'} {Math.abs(change).toFixed(2)}%
                    </span>
                    {hasValidCompareValue && (
                        <span className="font-medium">({formattedCompareValue})</span>
                    )}
                </div>
            </div>
        );
    };

    return (
        <div
            className={`metric-card ${cardClass || ''} ${isSelected ? selectedStyles : 'border-gray-200 hover:border-blue-300'}
                ${multiSelect ? 'hover:bg-blue-50/50' : ''}`}
            {...useTooltip(setActiveTooltip, componentKey)}
        >
            <div className='metric-card-wrapper'>
                {metricTooltip && activeTooltip && <Tooltip text={metricTooltip} className='!mr-2' />}
                <div className='metric-card-data'>
                    <h3 className='metric-card-label'>{label}</h3>
                    {multiSelect && (
                        <div className={`
                    metric-card-multiselect
                    ${isSelected
                                ? 'multiselect-active'
                                : null}
                `}>
                            {isSelected && (
                                <svg
                                    viewBox="0 0 24 24"
                                    className="multi-select-svg"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                >
                                    <path
                                        d="M5 13l4 4L19 7"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            )}
                        </div>
                    )}
                    <div className='metric-card-valuechange'>
                        <div className='metric-card-value'>{displayValue}</div>
                        <div className="valuechange-change">
                            <div className='valuechange-change-single'>
                                {showYOY && renderChangeIndicator(yearOverYearChange, yearOverYearValue, 'YOY')}
                            </div>
                            <div className='valuechange-change-single'>
                                {showPrevPeriod && renderChangeIndicator(previousPeriodChange, previousPeriodValue, 'Previous')}
                            </div>
                            <div className='valuechange-change-single'>
                                {showCustomPeriod && renderChangeIndicator(customPeriodChange, customPeriodValue, 'Custom')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
