import { useMemo } from 'react';
import { calculatePercentChange } from '../utils/dataProcessing';

/**
 * useDataStats Custom Hook
 * 
 * This hook calculates statistics for a set of metrics based on current and previous data.
 * It uses a provided metrics configuration to determine how to calculate each metric.
 * 
 * @param {Object} currentData - The current period's data
 * @param {Object} previousData - The previous period's data for comparison
 * @param {Object} metricsConfig - Configuration object defining how to calculate each metric
 * 
 * @returns {Object} An object containing calculated statistics for each metric:
 *   - value: The current value of the metric
 *   - previousValue: The previous value of the metric
 *   - percentChange: The percentage change between current and previous values
 */
export const useDataStats = (currentData, yearOverYearData, previousPeriodData, customPeriodData, metricsConfig) => {
    return useMemo(() => {
        if (!metricsConfig || typeof metricsConfig !== 'object') {
            return {};
        }

        const stats = {};

        for (const [key, config] of Object.entries(metricsConfig)) {
            if (!config || typeof config.calculate !== 'function') {
                continue;
            }

            // Ensure data objects exist
            const safeCurrentData = currentData || {};
            const safeYearOverYearData = yearOverYearData || {};
            const safePreviousPeriodData = previousPeriodData || {};
            const safeCustomPeriodData = customPeriodData || {};

            try {
                const currentValue = config.calculate(safeCurrentData);
                const yearOverYearValue = yearOverYearData ? config.calculate(safeYearOverYearData) : null;
                const previousPeriodValue = previousPeriodData ? config.calculate(safePreviousPeriodData) : null;
                const customPeriodValue = customPeriodData ? config.calculate(safeCustomPeriodData) : null;

                stats[key] = {
                    value: currentValue,
                    // Year over Year comparison
                    yearOverYearValue,
                    yearOverYearChange: yearOverYearValue !== null ?
                        calculatePercentChange(currentValue, yearOverYearValue) :
                        null,
                    // Previous Period comparison
                    previousPeriodValue,
                    previousPeriodChange: previousPeriodValue !== null ?
                        calculatePercentChange(currentValue, previousPeriodValue) :
                        null,
                    // Custom Period comparison
                    customPeriodValue,
                    customPeriodChange: customPeriodValue !== null ?
                        calculatePercentChange(currentValue, customPeriodValue) :
                        null
                };
            } catch (error) {
                console.error(`Error calculating stats for ${key}:`, error);
                stats[key] = {
                    value: 0,
                    yearOverYearValue: null,
                    yearOverYearChange: null,
                    previousPeriodValue: null,
                    previousPeriodChange: null,
                    customPeriodValue: null,
                    customPeriodChange: null
                };
            }
        }

        return stats;
    }, [currentData, yearOverYearData, previousPeriodData, customPeriodData, metricsConfig]);
};
