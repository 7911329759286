import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon, DocumentArrowUpIcon } from '@heroicons/react/24/solid';
import { useGlobalData } from '../../contexts/GlobalDataContext';
import { navSectionsConfig, getAvailableSections } from '../../config/navSectionsConfig';

const ReportSelect = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const dropdownRef = useRef(null);
    const { sourcesWithData = {}, loadingStates = {} } = useGlobalData() || {};

    const isLoading = Object.values(loadingStates).some(state => state === true);
    const availableOptions = getAvailableSections(sourcesWithData, true);

    const scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -190;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    const handleSelect = (option) => {
        setSelectedOption(option);
        scrollToSection(option.id);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleOutsideClick);
        }

        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [isOpen]);

    useEffect(() => {
        if (selectedOption && selectedOption.key !== 'top' && !sourcesWithData[selectedOption.apiKey]) {
            setSelectedOption(null);
        }
    }, [sourcesWithData, selectedOption]);

    const renderIcon = (option) => {
        if (option.iconComponent) {
            const IconComponent = option.iconComponent;
            return <IconComponent className={option.iconClasses} />;
        }
        if (option.icon) {
            return <img src={option.icon} alt={option.name} className="dropdown-option-icon" />;
        }
        return null;
    };

    return (
        <div className="rr-custom-dropdown" ref={dropdownRef}>
            <button
                type="button"
                className="dropdown-button"
                onClick={() => setIsOpen(!isOpen)}
                disabled={isLoading}
            >
                {selectedOption ? (
                    <span className="dropdown-button-item">
                        {renderIcon(selectedOption)}
                        {selectedOption.name}
                    </span>
                ) : (
                    'Jump to section'
                )}
                <ChevronDownIcon className="ml-2 h-5 w-5" aria-hidden="true" />
            </button>

            {isOpen && (
                <div className="dropdown-options">
                    <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {availableOptions.map((option) => (
                            <button
                                key={option.id}
                                className="dropdown-option"
                                role="menuitem"
                                onClick={() => handleSelect(option)}
                            >
                                {renderIcon(option)}
                                {option.name}
                            </button>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ReportSelect;
