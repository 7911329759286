/**
 * Cache expiration time in milliseconds.
 * Default is 5 minutes (5 * 60 * 1000).
 */
export const CACHE_EXPIRATION = 5 * 60 * 1000;

/**
 * Common API endpoint paths used throughout the application.
 * Centralizes endpoint management for easier updates.
 */
export const API_ENDPOINTS = {
    ACCOUNTS: '/user/accounts',
    REVIEWS: '/gbp/reviews',
    GA4_PERFORMANCE: '/ga4/performance'
};

/**
 * List of additional API endpoints for fetching specific data metrics.
 * Used primarily with GA4 and ecommerce data fetching.
 */
export const additionalEndpoints = [
    '/forms', '/calls', '/organic', '/ecommerce', '/chats',
    '/sources', '/devices', '/new', '/pages', '/sessions', '/bouncerate',
    '/averageduration', '/ecommerce', '/ecommerce/items', '/ecommerce/categories'
];

/**
 * Gets the appropriate ID parameter object based on data type.
 * Different APIs require different ID field names.
 * 
 * @param {string} dataType - The type of data being fetched
 * @param {string} id - The ID value
 * @returns {Object} Object with the correctly named ID field
 */
export const getIdParam = (dataType, id) => {
    switch (dataType) {
        case 'ga4':
        case 'ga4-organic':
        case 'ecommerce':
            return { property: id };
        case 'gbp':
            return { mapsId: id };
        case 'gsc':
            return { siteUrl: id };
        default:
            return { id };
    }
};
