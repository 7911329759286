import { formatNumber, formatPercentage } from '../utils/dataProcessing';

export const metricsConfig = {
    impressions: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    const value = parseInt(row.impressions) || 0;
                    return total + value;
                }, 0);
            } catch (error) {
                console.error('Error calculating impressions:', error);
                return 0;
            }
        },
        label: 'Impressions - Google Search',
        getLabel: () => 'Impressions - Google Search',
        formatValue: (value) => formatNumber(value)
    },
    clicks: {
        calculate: (data) => {
            try {
                if (!data?.rows) return 0;
                return data.rows.reduce((total, row) => {
                    const value = parseInt(row.clicks) || 0;
                    return total + value;
                }, 0);
            } catch (error) {
                console.error('Error calculating clicks:', error);
                return 0;
            }
        },
        label: 'Clicks - Google Search',
        getLabel: () => 'Clicks - Google Search',
        formatValue: (value) => formatNumber(value)
    },
};

// Controls which metrics show in the chart area
export const displayedChartMetrics = [
    'impressions',
    'clicks',
];

// Transform the data for the line chart
export const transformGSCData = (data, yearOverYearData, previousPeriodData, customPeriodData) => {
    if (!data?.rows) return {};

    const transformDateData = (inputData) => {
        if (!inputData?.rows) return [];

        return inputData.rows.map(row => ({
            date: row.date,
            impressions: parseInt(row.impressions) || 0,
            clicks: parseInt(row.clicks) || 0,
            ctr: parseFloat(row.ctr) * 100 || 0,
            position: parseFloat(row.position) || 0
        }));
    };

    const chartData = transformDateData(data);
    const yearOverYearChartData = transformDateData(yearOverYearData);
    const previousPeriodChartData = transformDateData(previousPeriodData);
    const customPeriodChartData = transformDateData(customPeriodData);

    return {
        chartData: chartData.map((currentItem, index) => ({
            ...currentItem,
            yearOverYear: yearOverYearChartData[index] || null,
            previousPeriod: previousPeriodChartData[index] || null,
            customPeriod: customPeriodChartData[index] || null
        }))
    };
};

export const transformKeywordsData = (data) => {
    if (!data?.rows || !Array.isArray(data.rows)) return { byDate: {}, aggregated: { impressions: [], clicks: [] } };

    // Group by date
    const byDate = data.rows.reduce((acc, row) => {
        const date = row.keys[0];
        const query = row.keys[1];
        
        if (!acc[date]) {
            acc[date] = [];
        }
        
        acc[date].push({
            query,
            impressions: parseInt(row.impressions) || 0,
            clicks: parseInt(row.clicks) || 0,
            ctr: parseFloat(row.ctr) || 0,
            position: parseFloat(row.position) || 0
        });
        
        return acc;
    }, {});

    // Aggregate across all dates
    const aggregated = data.rows.reduce((acc, row) => {
        const query = row.keys[1];
        const existing = acc[query] || { 
            query, 
            impressions: 0, 
            clicks: 0
        };
        
        acc[query] = {
            query,
            impressions: existing.impressions + (parseInt(row.impressions) || 0),
            clicks: existing.clicks + (parseInt(row.clicks) || 0)
        };
        
        return acc;
    }, {});

    // Sort by impressions (descending)
    const sortedByImpressions = Object.values(aggregated)
        .sort((a, b) => b.impressions - a.impressions);

    return {
        byDate,
        aggregated: {
            impressions: sortedByImpressions.slice(0, 10),  // Top 10 for the metrics cards
            clicks: sortedByImpressions                     // Full sorted list for the keyword table
                .sort((a, b) => b.clicks - a.clicks)
                .slice(0, 10),
            topRanked: sortedByImpressions.slice(0, 50)    // Top 50 for the keyword table
        }
    };
};

// Transform pages data with date dimension
export const transformPagesData = (data) => {
    if (!data?.rows || !Array.isArray(data.rows)) return { byDate: {}, aggregated: { impressions: [], clicks: [] } };

    // Group by date
    const byDate = data.rows.reduce((acc, row) => {
        const date = row.keys[0];  // First key is date
        const page = row.keys[1];  // Second key is page
        
        if (!acc[date]) {
            acc[date] = [];
        }
        
        acc[date].push({
            page,
            impressions: parseInt(row.impressions) || 0,
            clicks: parseInt(row.clicks) || 0,
            ctr: parseFloat(row.ctr) || 0,
            position: parseFloat(row.position) || 0
        });
        
        return acc;
    }, {});

    // Aggregate across all dates
    const aggregated = data.rows.reduce((acc, row) => {
        const page = row.keys[1];
        const existing = acc[page] || { page, impressions: 0, clicks: 0 };
        
        acc[page] = {
            page,
            impressions: existing.impressions + (parseInt(row.impressions) || 0),
            clicks: existing.clicks + (parseInt(row.clicks) || 0)
        };
        
        return acc;
    }, {});

    // Sort and get top results
    const sortedImpressions = Object.values(aggregated)
        .sort((a, b) => b.impressions - a.impressions)
        .slice(0, 10);
        
    const sortedClicks = Object.values(aggregated)
        .sort((a, b) => b.clicks - a.clicks)
        .slice(0, 10);

    return {
        byDate,
        aggregated: {
            impressions: sortedImpressions,
            clicks: sortedClicks
        }
    };
};


export const tableColumns = [
    { key: 'keys', label: 'Most Common Search Queries' }
];
