import { useCallback } from 'react';
import { calculatePreviousPeriod } from '../../utils/dataContextUtils';
import { additionalEndpoints } from '../../config/dataContextConfig';
import { addDays } from 'date-fns';

/**
 * Custom hook for loading previous period comparison data.
 * Handles fetching and processing data for the period immediately before the current period.
 */
export const usePreviousPeriod = (props) => {
    const {
        accounts,
        currentStartDate,
        currentEndDate,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setPreviousStartDate,
        setPreviousEndDate,
        setIsPreviousDataLoaded,
        dataTypes,
        forceRefresh
    } = props;

    const loadPreviousData = useCallback(async () => {
        if (!accounts || !currentStartDate || !currentEndDate || !fetchDataForType) return;

        // Clear existing data when forced refresh
        if (forceRefresh) {
            setGlobalData(prev => ({
                ...prev,
                previousPeriod: {}
            }));
        }

        try {
            setLoadingStates(prev => ({ ...prev, previousPeriod: true }));
            setIsPreviousDataLoaded(false);

            const originalPreviousPeriod = calculatePreviousPeriod(currentStartDate, currentEndDate);
            // Add one day to the start date
            const adjustedPreviousStartDate = addDays(new Date(originalPreviousPeriod.startDate), 1);
            const previousEndDate = new Date(originalPreviousPeriod.endDate);

            setPreviousStartDate(adjustedPreviousStartDate);
            setPreviousEndDate(previousEndDate);

            const previousPeriod = {
                startDate: adjustedPreviousStartDate.toISOString().split('T')[0],
                endDate: originalPreviousPeriod.endDate
            };

            const newData = { previousPeriod: {} };

            // Prepare all promises in parallel
            const allPromises = dataTypes.flatMap(({ key }) => {
                if (!accounts?.[key] || key === 'reviews') return [];

                newData.previousPeriod[key] = {};

                return accounts[key].map(async (id) => {
                    try {
                        const apiKey = key === 'ga4-organic' ? 'ga4' : key;

                        // Base data fetch
                        const result = await fetchDataForType(
                            apiKey,
                            id,
                            null,
                            null,
                            previousPeriod,
                            '',
                            false,
                            true,
                            key === 'ga4-organic'
                        );

                        if (result.previousPeriod) {
                            newData.previousPeriod[key][id] = result.previousPeriod;

                            // GA4 specific endpoints
                            if ((key === 'ga4' || key === 'ga4-organic') && result.previousPeriod) {
                                const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                                    const endpointResult = await fetchDataForType(
                                        apiKey,
                                        id,
                                        null,
                                        null,
                                        previousPeriod,
                                        endpoint,
                                        false,
                                        true,
                                        key === 'ga4-organic'
                                    );
                                    return { endpoint: endpoint.slice(1), ...endpointResult };
                                });

                                const endpointResults = await Promise.all(endpointPromises);
                                endpointResults.forEach(({ endpoint, previousPeriod: periodData }) => {
                                    if (periodData) {
                                        newData.previousPeriod[key][id] = {
                                            ...newData.previousPeriod[key][id],
                                            [endpoint]: periodData
                                        };
                                    }
                                });
                            }
                        }

                        // Ecommerce specific logic
                        if (key === 'ecommerce') {
                            const [overview, items, categories] = await Promise.all([
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce', false, true),
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce/items', false, true),
                                fetchDataForType('ga4', id, null, null, previousPeriod, '/ecommerce/categories', false, true)
                            ]);

                            newData.previousPeriod[key][id] = {
                                overview: overview?.previousPeriod,
                                items: items?.previousPeriod,
                                categories: categories?.previousPeriod
                            };
                        }
                    } catch (error) {
                        console.error(`Error fetching previous period data for ${key}:`, error);
                    }
                });
            });

            // Execute all promises in parallel
            await Promise.all(allPromises);
            console.log('previous period data', newData);
            setGlobalData(prev => ({
                ...prev,
                previousPeriod: newData.previousPeriod
            }));

            setIsPreviousDataLoaded(true);
        } finally {
            setLoadingStates(prev => ({ ...prev, previousPeriod: false }));
        }
    }, [
        accounts,
        currentStartDate,
        currentEndDate,
        fetchDataForType,
        setLoadingStates,
        setGlobalData,
        setPreviousStartDate,
        setPreviousEndDate,
        setIsPreviousDataLoaded,
        dataTypes,
        forceRefresh
    ]);

    return { loadPreviousData };
};
