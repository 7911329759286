import React, { useState, useEffect, useRef } from 'react';
import { Datepicker, Select, Button } from 'flowbite-react';
import { datePickerTheme } from '../../config/datePickerTheme';
import { startOfDay, subDays, subMonths } from 'date-fns';

// Custom hook for detecting clicks outside of an element
const useClickOutside = (ref, callback) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, callback]);
};

const DashboardFilters = ({ startDate, endDate, setStartDate, setEndDate, onDateChange, initialSelectedRange = 'last3months' }) => {
    const [selectedRange, setSelectedRange] = useState(initialSelectedRange);
    const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = useState(false);
    const [tempStartDate, setTempStartDate] = useState(null);
    const [tempEndDate, setTempEndDate] = useState(null);
    const [customDateRange, setCustomDateRange] = useState(null);
    const datePickerRef = useRef(null);

    const CalendarIcon = () => (
        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="M20 4a2 2 0 0 0-2-2h-2V1a1 1 0 0 0-2 0v1h-3V1a1 1 0 0 0-2 0v1H6V1a1 1 0 0 0-2 0v1H2a2 2 0 0 0-2 2v2h20V4ZM0 18a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V8H0v10Zm5-8h10a1 1 0 0 1 0 2H5a1 1 0 0 1 0-2Z" />
        </svg>
    );

    const dateRanges = [
        { label: 'Custom', value: 'custom' },
        { label: 'Yesterday', value: 'yesterday' },
        { label: 'Last 7 days', value: 'last7days' },
        { label: 'Last 28 days', value: 'last28days' },
        { label: 'This month', value: 'thisMonth' },
        { label: 'Last month', value: 'lastMonth' },
        { label: 'Last 3 Months', value: 'last3months' },
        { label: 'Last 12 Months', value: 'last12months' },
        { label: 'This quarter', value: 'thisQuarter' },
        { label: 'Last quarter', value: 'lastQuarter' },
        { label: 'This year', value: 'thisYear' },
    ];

    const formatDate = (date) => {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
    };

    const updateDateRange = (range) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);
        yesterday.setDate(yesterday.getDate() - 1);

        let start = new Date();
        let end = new Date(yesterday);

        switch (range) {
            case 'yesterday':
                start = new Date(yesterday);
                end = new Date(yesterday);
                break;
            case 'last7days':
                start = new Date(yesterday);
                start.setDate(yesterday.getDate() - 6);
                end = new Date(yesterday);
                break;
            case 'last28days':
                start = new Date(yesterday);
                start.setDate(yesterday.getDate() - 27);
                end = new Date(yesterday);
                break;
            case 'thisMonth':
                start = new Date(yesterday.getFullYear(), yesterday.getMonth(), 1);
                end = new Date(yesterday);
                break;
            case 'lastMonth':
                start = new Date(yesterday.getFullYear(), yesterday.getMonth() - 1, 1);
                end = new Date(yesterday.getFullYear(), yesterday.getMonth(), 0);
                break;
            case 'last3months':
                const today = new Date();
                end = startOfDay(subDays(today, 1));
                end.setHours(0, 0, 0, 0);
                start = startOfDay(subMonths(today, 3));
                start.setHours(0, 0, 0, 0);
                break;
            case 'last12months':
                start = new Date(yesterday);
                start.setFullYear(yesterday.getFullYear() - 1);
                start.setDate(1); // First day of the month
                end = new Date(yesterday);
                break;
            case 'lastQuarter':
                const lastQuarterMonth = Math.floor((yesterday.getMonth() - 3) / 3) * 3;
                start = new Date(yesterday.getFullYear(), lastQuarterMonth, 1);
                end = new Date(yesterday.getFullYear(), lastQuarterMonth + 3, 0);
                break;
            case 'thisQuarter':
                const quarterMonth = Math.floor(yesterday.getMonth() / 3) * 3;
                start = new Date(yesterday.getFullYear(), quarterMonth, 1);
                end = new Date(yesterday);
                break;
            case 'thisYear':
                start = new Date(yesterday.getFullYear(), 0, 1);
                end = new Date(yesterday);
                break;
            default:
                return;
        }

        // Ensure all dates are set to midnight
        start.setHours(0, 0, 0, 0);
        end.setHours(0, 0, 0, 0);

        setStartDate(start);
        setEndDate(end);
        onDateChange(start, end);
    };

    const handleRangeChange = (value) => {
        setSelectedRange(value);
        if (value === 'custom') {
            setIsCustomDatePickerOpen(true);
        } else if (value === 'customRange') {
            // Do nothing, already displaying custom range
        } else {
            setIsCustomDatePickerOpen(false);
            setCustomDateRange(null);

            // This will trigger the loading overlay via the data refetch
            updateDateRange(value);
        }
    };

    const handleApplyCustomDates = () => {
        setStartDate(tempStartDate);
        setEndDate(tempEndDate);
        onDateChange(tempStartDate, tempEndDate);
        setIsCustomDatePickerOpen(false);
        const customRangeLabel = `${formatDate(tempStartDate)} - ${formatDate(tempEndDate)}`;
        setCustomDateRange({ label: customRangeLabel, value: 'customRange' });
        setSelectedRange('customRange');
    };

    useClickOutside(datePickerRef, () => {
        if (isCustomDatePickerOpen) {
            handleApplyCustomDates();
        }
    });

    useEffect(() => {
        if (initialSelectedRange !== 'custom') {
            handleRangeChange(initialSelectedRange);
        }
    }, []);
    
    useEffect(() => {
        if (startDate && endDate) {
            // Create new date objects to break reference
            const newStartDate = new Date(startDate);
            const newEndDate = new Date(endDate);
            
            // Ensure proper time setting
            newStartDate.setHours(0, 0, 0, 0);
            newEndDate.setHours(0, 0, 0, 0);
            
            setTempStartDate(newStartDate);
            setTempEndDate(newEndDate);
        }
    }, [startDate, endDate]);

    const allDateRanges = customDateRange
        ? [customDateRange, ...dateRanges]
        : dateRanges;

    return (
        <div className="rr-date-filter">
            <div className='rr-date-filter-initial'>
                <h3>Date range</h3>
                <div className='rr-date-select'>
                    <Select
                        id="dateRange"
                        value={selectedRange}
                        onChange={(e) => handleRangeChange(e.target.value)}
                        className="w-full rr-datepicker"
                    >
                        {allDateRanges.map((range) => (
                            <option key={range.value} value={range.value}>
                                {range.label}
                            </option>
                        ))}
                    </Select>
                </div>
            </div>
            {isCustomDatePickerOpen && (
                <div ref={datePickerRef} className='rr-date-filter-inputs'>
                    <div className="relative mb-4">
                        <div className='rr-date-label'>Start date</div>
                        <Datepicker
                            id="datepicker-range-start"
                            name="start"
                            className="w-full rr-datepicker"
                            placeholder="Select date start"
                            onSelectedDateChanged={setTempStartDate}
                            icon={CalendarIcon}
                            defaultDate={tempStartDate}
                            theme={datePickerTheme}
                        />
                    </div>
                    <div className="relative mb-4">
                        <div className='rr-date-label'>End date</div>
                        <Datepicker
                            id="datepicker-range-end"
                            name="end"
                            className="w-full rr-datepicker"
                            placeholder="Select date end"
                            onSelectedDateChanged={setTempEndDate}
                            icon={CalendarIcon}
                            defaultDate={tempEndDate}
                            theme={datePickerTheme}
                        />
                    </div>
                    <div className='rr-datepicker-apply-wrapper'>
                        <Button className='rr-datepicker-apply' onClick={handleApplyCustomDates}>
                            Apply
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default DashboardFilters;
