import { useCallback } from 'react';
import { formatDate } from '../../utils/dataProcessing';
import { additionalEndpoints } from '../../config/dataContextConfig';
import { startOfDay, subDays } from 'date-fns';

export const useKpiData = (props) => {
    const {
        accounts,
        fetchDataForType,
        setLoadingStates,
        setKpiData,
        setIsKpiDataLoaded,
        dataTypes
    } = props;

    const loadKpiData = useCallback(async () => {
        if (!accounts || !fetchDataForType) return;

        try {
            setLoadingStates(prev => ({ ...prev, kpi: true }));
            setIsKpiDataLoaded(false);

            // Calculate all dates using date-fns for consistency
            const today = new Date();
            const yesterday = startOfDay(subDays(today, 1));

            // Current 4 week period (28 days ending yesterday)
            const current4wEnd = yesterday;
            const current4wStart = startOfDay(subDays(yesterday, 27)); // 27 to make it inclusive

            // Previous 4 week period (28 days before current period)
            const previous4wEnd = startOfDay(subDays(current4wStart, 1));
            const previous4wStart = startOfDay(subDays(previous4wEnd, 27));

            // Current 13 week period (91 days ending yesterday)
            const current13wEnd = yesterday;
            const current13wStart = startOfDay(subDays(yesterday, 90)); // 90 to make it inclusive

            // Previous 13 week period (91 days before current period)
            const previous13wEnd = startOfDay(subDays(current13wStart, 1));
            const previous13wStart = startOfDay(subDays(previous13wEnd, 90));

            const dateRanges = {
                current4w: {
                    startDate: formatDate(current4wStart),
                    endDate: formatDate(current4wEnd)
                },
                previous4w: {
                    startDate: formatDate(previous4wStart),
                    endDate: formatDate(previous4wEnd)
                },
                current13w: {
                    startDate: formatDate(current13wStart),
                    endDate: formatDate(current13wEnd)
                },
                previous13w: {
                    startDate: formatDate(previous13wStart),
                    endDate: formatDate(previous13wEnd)
                }
            };
            
            const newData = {
                current4w: { dateRange: dateRanges.current4w },
                previous4w: { dateRange: dateRanges.previous4w },
                current13w: { dateRange: dateRanges.current13w },
                previous13w: { dateRange: dateRanges.previous13w }
            };

            // Rest of the implementation remains the same
            for (const [rangeKey, dateRange] of Object.entries(dateRanges)) {
                const promises = dataTypes.flatMap(({ key }) => {
                    if (!accounts?.[key] || key === 'reviews') return [];

                    newData[rangeKey][key] = {};

                    return accounts[key].map(async (id) => {
                        try {
                            const apiKey = key === 'ga4-organic' ? 'ga4' : key;

                            if (key === 'ecommerce') {
                                const ecommerceData = await fetchDataForType('ga4', id, null, null, dateRange, '/ecommerce', false, true);
                                if (ecommerceData?.previousPeriod) {
                                    newData[rangeKey][key][id] = ecommerceData.previousPeriod;
                                }
                                return;
                            }

                            const result = await fetchDataForType(
                                apiKey,
                                id,
                                null,
                                null,
                                dateRange,
                                '',
                                false,
                                true,
                                key === 'ga4-organic'
                            );

                            if (result.previousPeriod) {
                                newData[rangeKey][key][id] = result.previousPeriod;

                                if ((key === 'ga4' || key === 'ga4-organic')) {
                                    const endpointPromises = additionalEndpoints.map(async (endpoint) => {
                                        const endpointResult = await fetchDataForType(
                                            apiKey,
                                            id,
                                            null,
                                            null,
                                            dateRange,
                                            endpoint,
                                            false,
                                            true,
                                            key === 'ga4-organic'
                                        );
                                        return { endpoint: endpoint.slice(1), ...endpointResult };
                                    });

                                    const endpointResults = await Promise.all(endpointPromises);
                                    endpointResults.forEach(({ endpoint, previousPeriod: periodData }) => {
                                        if (periodData) {
                                            newData[rangeKey][key][id] = {
                                                ...newData[rangeKey][key][id],
                                                [endpoint]: periodData
                                            };
                                        }
                                    });
                                }
                            }
                        } catch (error) {
                            console.error(`Error fetching KPI data for ${key}:`, error);
                        }
                    });
                });

                await Promise.all(promises);
            }
            
            setKpiData(newData);
            setIsKpiDataLoaded(true);
        } catch (error) {
            console.error('Error loading KPI data:', error);
        } finally {
            setLoadingStates(prev => ({ ...prev, kpi: false }));
        }
    }, [accounts, fetchDataForType, setLoadingStates, setKpiData, setIsKpiDataLoaded, dataTypes]);

    return { loadKpiData };
};
